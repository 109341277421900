import React, { useEffect, useState } from 'react'
import { Maybe } from '@graphql-tools/utils'
import { useRouter } from 'next/router'
import { Else, If, Then } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { ArrowRightIcon } from '@/atoms/Icons/ArrowRightIcon'
import { Image } from '@/atoms/Image'
import { AsH3, AsLabel, HeadingXS, ParagraphMD, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { EmailCaptureFormInput } from '@/views/SignUpView/EmailCapture/EmailCaptureFormInput/EmailCaptureFormInput'
import { EmailCaptureSignupModal } from '@/views/WatchTheatricalView/GetInvolvedSquare/EmailCaptureSignupModal'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'

interface GetInvolvedSquareProps {
  icon: Maybe<string>
  title: Maybe<string>
  description: Maybe<string>
  buttonText: Maybe<string>
  buttonHref: Maybe<string>
  backgroundImg: Maybe<string>
  emailListId: Maybe<string>
  slug: string
  type: Maybe<'Button' | 'Email Capture' | string>
  className?: string
}

export const GetInvolvedSquare: React.FC<GetInvolvedSquareProps> = ({
  icon,
  title,
  description,
  buttonText,
  buttonHref,
  backgroundImg,
  emailListId,
  slug,
  type,
  className,
}) => {
  const { t } = useTranslate('theatrical-presales')
  const { name: filmName } = useWatchTheatricalContext()
  const [isSignupModalOpen, setSignupModalOpen] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    if (router.asPath.indexOf('#signup') >= 0) {
      setSignupModalOpen(true)
    }
  }, [router.asPath])

  return (
    <div className={className}>
      <div
        className="flex flex-col items-center justify-center bg-cover bg-center px-12 py-16 text-center md:px-4 md:py-5 lg:px-10 lg:py-8 xl:px-20 xl:py-16"
        style={{
          backgroundImage: backgroundImg
            ? `url("${getCloudinaryImageUrl({ path: backgroundImg, transforms: 'g_face,c_fill,w_710,o_25,' })}")`
            : '',
        }}
      >
        <div>
          {icon && (
            <div className="flex h-10 shrink-0 justify-center lg:mb-4">
              <Image alt={title ?? ''} className="h-10" src={icon} height={40} width={40} />
            </div>
          )}
          {title && (
            <HeadingXS
              as={AsH3}
              weight="bold"
              color="white"
              className="md:photon-title-sm lg:photon-title-lg xl:photon-heading-xs mb-2 md:mb-2 lg:mb-4"
            >
              {title}
            </HeadingXS>
          )}
          {description && (
            <ParagraphMD
              color="core-gray-300"
              className="sm:photon-caption-xs md:photon-caption-sm lg:photon-paragram-sm xl:photon-paragraph-md mb-6"
            >
              {description}
            </ParagraphMD>
          )}
        </div>
        <If condition={type === 'Email Capture'}>
          <Then>
            {emailListId && (
              <div id="signup-for-updates" className="w-full scroll-mt-[200px]">
                <EmailCaptureFormInput
                  position="hub-cta"
                  subscriptionGroupId={emailListId}
                  destination={paths.watch.index}
                  projectSlug={slug}
                  placeholder={t('enterEmail', 'Enter Email')}
                  formClassName="relative max-w-lg mx-auto"
                  inputClassName="rounded-lg w-full p-4 text-gray-950"
                  formAriaLabel={title as string | undefined}
                >
                  <Button
                    variant="black"
                    className="absolute right-2 top-1/2 -translate-y-1/2 rounded-lg bg-black px-2"
                    aria-label={t('submitEmail', 'Submit Email')}
                  >
                    <ArrowRightIcon color="white" />
                  </Button>
                </EmailCaptureFormInput>
                <EmailCaptureSignupModal
                  isOpen={isSignupModalOpen}
                  onClose={() => {
                    setSignupModalOpen(false)
                    router.replace(router.asPath.split('#')[0])
                  }}
                  filmName={filmName as string}
                  emailListId={emailListId}
                  projectSlug={slug}
                />
              </div>
            )}
          </Then>
          <Else>
            {Boolean(buttonText && buttonHref) && (
              <LinkButton href={buttonHref ?? '#'} variant="white" className="px-8 py-4 md:px-8 lg:px-16">
                <TitleXS as={AsLabel} weight="bold" color="black">
                  {buttonText}
                </TitleXS>
              </LinkButton>
            )}
          </Else>
        </If>
      </div>
    </div>
  )
}
