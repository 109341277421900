import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Case, Else, If, Switch, Then, When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { Button } from '@/atoms/Button'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { CaptionMD, CaptionSM, EyebrowMD, HeadingSM, LabelXS } from '@/atoms/Text'
import { projectTypes } from '@/constants/projectTypes'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal'
import {
  CatalogMovie,
  CatalogSeries,
  formatTitleDuration,
  formatTitleRating,
  formatTitleReleaseYear,
} from '@/services/ContentCatalog'
import { DigitalPageData } from '@/services/DigitalService'
import { PlayableLivestream } from '@/services/LivestreamService'
import { WhereToWatch } from '@/services/WhereToWatchService/WhereToWatchService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'
import { DigitalWhereToWatchSection } from '@/views/WatchTheatricalView/WhereToWatch'
import { CastRail } from './FilmDetails/CastRail'

interface DigitalStreamingViewProps {
  livestreams: PlayableLivestream[] | null
  catalogTitle: CatalogMovie | CatalogSeries
  whereToWatch: WhereToWatch
  digitalPageData: DigitalPageData
}

export const DigitalStreamingView: React.FC<DigitalStreamingViewProps> = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const { digitalPageData, catalogTitle, whereToWatch } = props
  const projectMeta = useProjectMeta()
  const { t } = useTranslate('theatrical-presales')
  const { name, filmDetails } = useWatchTheatricalContext()
  const { heroDesktopBackgroundUrl, heroTabletBackgroundUrl, heroMobileBackgroundUrl } = digitalPageData
  const { trailerSourceUrl } = filmDetails || {}

  const projectName = name as string
  const rating = formatTitleRating(catalogTitle)
  const releaseYear = formatTitleReleaseYear(catalogTitle)
  const duration = formatTitleDuration(catalogTitle)

  const handleReadMoreOrLess = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    const handleResize = () => {
      if (catalogTitle) {
        const element = document.getElementById('show-description')

        if (element) {
          setIsTruncated(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
        }
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [catalogTitle])

  const handleModalClick = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  return (
    <main className="relative">
      <section aria-labelledby="cta-status-line" id="hero" className="relative h-screen w-full lg:h-full">
        <ResponsiveBackgroundImage
          src={heroDesktopBackgroundUrl}
          srcUrls={{
            xs: heroMobileBackgroundUrl,
            sm: heroMobileBackgroundUrl,
            md: heroTabletBackgroundUrl,
            lg: heroDesktopBackgroundUrl,
            xl: heroDesktopBackgroundUrl,
            '2xl': heroDesktopBackgroundUrl,
            '3xl': heroDesktopBackgroundUrl,
          }}
          preBackgroundStyles="linear-gradient(270deg, rgba(21, 21, 21, 0.50) 0%, rgba(21, 21, 21, 0.00) 70%), "
          className="mt-16 h-full max-h-[80vh] w-full bg-core-gray-950 bg-cover object-cover lg:mt-0 lg:max-h-[820px]"
        />
        <div className="absolute inset-0 -bottom-1 hidden bg-gradient-to-t from-core-gray-950 to-transparent lg:flex" />
        <div className="absolute inset-0 -bottom-1 flex bg-gradient-to-b from-core-gray-950 to-transparent lg:hidden" />
        <div className="relative flex h-full w-full flex-col gap-y-28 px-4 pt-16 md:px-16 lg:flex-row lg:justify-between se:gap-y-16 3xl:mx-auto 3xl:max-w-[2560pxpx]">
          <div className="flex max-w-[363px] flex-col items-start justify-start gap-2 lg:min-h-[600px] lg:justify-center 2xl:min-h-[750px]">
            <HeadingSM color="white" weight="bold" className="mt-8">
              {projectName}
            </HeadingSM>
            <CaptionSM
              color="core-gray-400"
              id="show-description"
              className={classNames('text-left transition-[max-height] duration-[600ms] ease-in-out', {
                'max-h-[60px] line-clamp-3': !expanded,
                'overflow-hidden max-h-96': expanded,
              })}
            >
              {catalogTitle.description.long}
            </CaptionSM>
            <When condition={isTruncated}>
              <button onClick={handleReadMoreOrLess}>
                <LabelXS className="cursor-pointer py-2 text-xs" color="white" weight="semibold">
                  <If condition={expanded}>
                    <Then>{t('readLess', 'Read less')}</Then>
                    <Else>{t('readMore', 'Read more')}</Else>
                  </If>
                </LabelXS>
              </button>
            </When>
            <div className="mt-4 flex flex-row flex-wrap justify-center gap-2 text-white md:justify-start">
              <CaptionMD className="whitespace-nowrap">
                <Switch>
                  <Case condition={projectMeta.projectType === projectTypes.series}>
                    <Translate i18nKey="tvSeries" t={t}>
                      TV Series
                    </Translate>
                  </Case>
                  <Case condition={projectMeta.projectType === projectTypes.movie}>
                    <Translate i18nKey="movie" t={t}>
                      Movie
                    </Translate>
                  </Case>
                  <Case condition={projectMeta.projectType === projectTypes.podcast}>
                    <Translate i18nKey="podcast" t={t}>
                      Podcast
                    </Translate>
                  </Case>
                </Switch>
              </CaptionMD>
              {projectMeta?.metadata?.genres?.length && projectMeta.metadata.genres.length > 0 && (
                <CaptionMD>•</CaptionMD>
              )}
              {projectMeta?.metadata?.genres?.map((genre: string, index) => {
                return (
                  <div className="flex gap-2" key={genre}>
                    <CaptionMD>{genre}</CaptionMD>
                    <When condition={index < (projectMeta?.metadata?.genres?.length ?? 0) - 1}>
                      <CaptionMD>•</CaptionMD>
                    </When>
                  </div>
                )
              })}
            </div>
            <div>
              <div className="flex gap-[10px] lg:gap-4">
                {rating && (
                  <div className="w-fit whitespace-nowrap rounded-md border-[1px] border-core-gray-800 px-3 py-0.5">
                    <EyebrowMD color="core-gray-400" weight="semibold">
                      {formatTitleRating(props.catalogTitle)}
                    </EyebrowMD>
                  </div>
                )}
                {releaseYear && (
                  <div className="w-fit whitespace-nowrap rounded-md border-[1px] border-core-gray-800 px-3 py-0.5">
                    <EyebrowMD color="core-gray-400" weight="semibold">
                      {releaseYear}
                    </EyebrowMD>
                  </div>
                )}
                {duration && (
                  <div className="w-fit whitespace-nowrap rounded-md border-[1px] border-core-gray-800 px-3 py-0.5">
                    <EyebrowMD color="core-gray-400" weight="semibold">
                      {duration}
                    </EyebrowMD>
                  </div>
                )}
              </div>
            </div>
            <Button
              variant="white"
              className="mt-2 flex max-h-10 flex-row gap-1.5 rounded-[10px] px-2 py-[11px] text-core-gray-950"
              onClick={handleModalClick}
            >
              <PlayIcon size={24} color="core-gray-950" />
              {t('watchTrailer', 'Watch trailer')}
            </Button>
          </div>
          <When condition={Boolean(whereToWatch)}>
            <div className="flex items-center">
              <DigitalWhereToWatchSection
                projectName={projectName}
                projectSlug={projectMeta.slug}
                whereToWatch={whereToWatch}
              />
            </div>
          </When>
        </div>
      </section>
      <section className="relative">
        <LazyHydrate whenVisible>
          <div className="mt-16 px-4 md:mt-24 md:px-16 lg:mt-8 se:mt-72 3xl:mx-auto 3xl:max-w-[2560pxpx]">
            <CastRail catalogTitle={catalogTitle} />
          </div>
        </LazyHydrate>
      </section>
      <YoutubeVideoModal youTubeUrl={trailerSourceUrl} handleClose={handleModalClick} showModal={showModal} />
    </main>
  )
}
