import React, { useMemo } from 'react'
import { colors } from '@/constants/colors'
import { LightFranchise } from '@/services/FranchiseService'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService/TheatricalPreReleaseService'
import { ReactFCC } from '@/types/react'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary/cloudinary'
import { useLocale } from '@/utils/LocaleUtil'

interface FormattedImage {
  cloudinaryPath: string
  altText?: string | null
}

interface WatchTheatricalContextProviderValues extends GetTheatricalPreReleasePageDataResult {
  brandColor: string
  friendlyTheatricalReleaseDate: string | null
  hasPressPage: boolean | null | undefined
  hasAmbassadorPage: boolean | null | undefined
  logoCloudinaryPath: string
  slug: string
  images: FormattedImage[]
  franchise?: LightFranchise
}

const WatchTheatricalContext = React.createContext<WatchTheatricalContextProviderValues>(
  {} as WatchTheatricalContextProviderValues,
)

interface WatchTheatricalContextProviderProps {
  pageData: NonNullable<GetTheatricalPreReleasePageDataResult>
  franchise?: LightFranchise
}

export const WatchTheatricalContextProvider: ReactFCC<WatchTheatricalContextProviderProps> = ({
  franchise,
  pageData,
  children,
}) => {
  const { locale } = useLocale()
  const { filmDetails } = pageData
  const friendlyTheatricalReleaseDate = filmDetails?.theatricalReleaseDateOverrideText
    ? filmDetails?.theatricalReleaseDateOverrideText
    : getReleaseDate(locale, filmDetails?.theatricalReleaseDate)
  const value = useMemo(() => {
    return {
      ...pageData,
      slug: pageData.slug as string,
      logoCloudinaryPath: buildCloudinaryImagePathFromContentfulObject(pageData.logo),
      brandColor: filmDetails?.brandColor ?? colors.oxide,
      friendlyTheatricalReleaseDate,
      hasPressPage: pageData.hasPressPage,
      hasAmbassadorPage: pageData.hasAmbassadorPage,
      franchise,
      images: filmDetails?.imagesCollection?.items
        ?.map((image) => {
          if (!image?.item) return null

          return {
            cloudinaryPath: buildCloudinaryImagePathFromContentfulObject(image.item),
            altText: image.altText,
          }
        })
        .filter((v) => !!v) as FormattedImage[],
    }
  }, [pageData, friendlyTheatricalReleaseDate, filmDetails, franchise])

  return <WatchTheatricalContext.Provider value={value}>{children}</WatchTheatricalContext.Provider>
}

export const useWatchTheatricalContext = () => {
  return React.useContext(WatchTheatricalContext)
}

export function isDateTime(date: Date) {
  return !isNaN(date.getTime())
}

export function getReleaseDate(locale: string, str?: string | null) {
  if (str) {
    const date = new Date(str)

    if (isDateTime(date)) {
      return Intl.DateTimeFormat(locale, { month: 'long', day: 'numeric', timeZone: 'UTC' }).format(date)
    } else {
      return str
    }
  }

  return null
}
