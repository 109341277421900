import React, { useRef } from 'react'
import styles from './ImageGallery.module.css'
import classNames from 'classnames'
import Slider from 'react-slick'
import { UpArrowCircleIcon } from '@/atoms/Icons/UpArrowCircleIcon'
import { Image } from '@/atoms/Image'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'

interface ReactSlickArrowProps {
  currentSlide?: number
  slideCount?: number
  onClick?: () => void
  style?: Record<string, string | number>
}

const getChevronClassNames = (visible: boolean, className?: string) =>
  classNames(
    'cursor-pointer max-w-12 min-w-12',
    'h-full w-8 md:w-12 xl:w-16',
    'z-10 absolute top-0',
    '!flex items-center justify-center',
    className,
  )

export interface ImageGalleryProps {
  images: {
    cloudinaryPath: string
    altText?: string | null
  }[]
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const slider = useRef<Slider>(null)

  if (!images?.length) return null

  const ReactSlickChevronRight: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props

    return (
      <div
        style={style}
        className={getChevronClassNames(true, 'right-[5%] lg:right-[20%] translate-x-1/2 group-image-gallery-handle')}
        onClick={onClick}
      >
        <UpArrowCircleIcon
          className="rotate-90 !stroke-core-gray-300 pl-1 group-image-gallery-handle-hover:!stroke-white"
          color="inherit"
          size={56}
        />
      </div>
    )
  }

  const ReactSlickChevronLeft: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props

    return (
      <div
        style={style}
        className={getChevronClassNames(true, 'left-[5%] lg:left-[20%] -translate-x-1/2 group-image-gallery-handle')}
        onClick={onClick}
      >
        <UpArrowCircleIcon
          className="-rotate-90 !stroke-core-gray-300 pl-1 group-image-gallery-handle-hover:!stroke-white"
          color="inherit"
          size={56}
        />
      </div>
    )
  }

  return (
    <div className={styles.imageGallery}>
      <HorizontalItemScroller
        className="overflow-x-hidden py-10"
        centerMode
        slidesToShow={3}
        slidesToScroll={3}
        infinite
        centerPadding="50px"
        speed={500}
        responsive={[
          {
            breakpoint: 1536,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 10,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
        ]}
        useCSS
        useTransform
        waitForAnimate
        cssEase="ease"
        easing="easeQuadOut"
        nextArrow={<ReactSlickChevronRight onClick={slider?.current?.slickNext} />}
        prevArrow={<ReactSlickChevronLeft onClick={slider?.current?.slickPrev} />}
      >
        {images.map(({ cloudinaryPath, altText }) => {
          return (
            <div key={cloudinaryPath}>
              <Image
                height={1595}
                width={2837}
                src={`c_fill,q_auto,ar_16:9,g_north,w_2837,h_1595/${cloudinaryPath}`}
                alt={altText ?? ''}
                className="overflow-hidden rounded-lg object-cover lg:rounded-2xl"
              />
            </div>
          )
        })}
      </HorizontalItemScroller>
    </div>
  )
}
